import React, { ReactElement } from "react"
import { NavBar, NavBarMobile } from "@/layout"

const NotFoundPage = (): ReactElement => (
    <>
        <NavBarMobile/>
        <div className={'container-not-found'}>
            <NavBar className={'container'}/>

            <div className={'container container-not-found-content'}>
                <h1>Oops!!! Page Not Found. {' '}
                    <a href={'/#request'} className={'container-not-found-content-link'}>
                        Click here to contact us</a>
                </h1>
            </div>
        </div>
    </>

)

export default NotFoundPage
